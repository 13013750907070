import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import icon1 from "../../../images/Component.svg";
import QualityCheck from "../../../images/QualityCheck.svg";
import QualityCheck2 from "../../../images/QualityCheck2.svg";
import iconnavigationclose from "../../../images/icon-navigation-close.svg";
import accesslink1 from "../../../images/accesslink1.svg";
import accesslink2 from "../../../images/accesslink2.svg";
import accesslink3 from "../../../images/accesslink3.svg";
import upload from "../../../images/upload.svg";
import relabel from "../../../images/relabel.svg";
import approve from "../../../images/approve.svg";
import fail from "../../../images/fail.svg";
import type from "../../../images/type.svg";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineSortDescending } from "react-icons/hi";
import { setActiveClaimPage } from "../../../store/actions/pagination";
import supportingimg from "../../../images/Imageplaceholder.png";
import { TiTick } from "react-icons/ti";
import { IoFileTrayStackedSharp } from "react-icons/io5";
import Popup from "../../../popup";
import Popup2 from "../../../popup2";
import { useHistory } from "react-router-dom";
import { getTask, selectedData } from "../../../store/actions/tasks";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import urls from "../../../env";
import toast, { Toaster } from "react-hot-toast";
import Pagination from "@mui/material/Pagination";
import mixpanel from "mixpanel-browser";
import * as events from "../../../utils/logger/events";
import { useLocalStorage } from "usehooks-ts";
import SearchComponent from "../../../components/SearchComponent";
import Button from "@mui/material/Button";
import ArrowBack from "@mui/icons-material/ArrowBack";

import LinearProgressWithLabel from "../../../components/LinearProgressWithLabel";

const allerrorDisplay = [];
const allQAErrors = [
  "Missing Item",
  "Extra Item",
  "Wrong Item",
  "Wrong Item measurement",
  "Wrong Wall measurement",
  "Inconsistent Label",
  "Not Enough information",
  "Basic Structure not modeled",
  "Basic Items not modeled",
  "Multiple rooms",
  "Other",
];

function QADashboard() {
  const history = useHistory();
  const formdata = new FormData();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);
  const activePagination = useSelector(
    (state) => state.pagination.activeClaimPage
  );

  const [loadingScreen, setLoadingScreen] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [founderror, setFounderror] = useState(false);
  const [founderror2, setFounderror2] = useState(null);
  const [founderror3, setFounderror3] = useState(null);
  const [approved, setapproved] = useState(null);
  const [ifapprove, setIfapprove] = useState(true);
  const [startd, setStartd] = useState(0);
  const [endd, setEndd] = useState(10);
  const [lastIndex, setLastIndex] = useState(10);
  const [DATA, setDATA] = useState(null);
  const [Erroname, setErroname] = useState("");
  const [Data, setData] = useState(DATA);
  const [claimpopup, setClaimpopup] = useState(false);
  const [claimpopupFailed, setClaimpopupFailed] = useState(false);
  const [claimedErrorText, setClaimedErrorText] = useState("");
  const [errordata, setErrordata] = useState(null);
  const [comment, setComment] = useState(null);
  const [errorpopup, setErrorpopup] = useState(false);
  const [afterPostErrorSuccess, setafterPostErrorSuccess] = useState(false);
  const [afterPostErrorFail, setafterPostErrorFail] = useState(false);
  const [Iconpopup, setIconpopup] = useState(false);
  const [IconId, setIconId] = useState(null);

  const [showinputfile, setShowinputfile] = useState(false);
  const [showinputdata, setShowinputdata] = useState([]);
  const [responseurldata, setResponseurldata] = useState("");

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [selectedItemId, setselectedItemId] = useState();
  const [selectedItemData, setselectedItemData] = useState();
  const [activeQaErrors, setActiveQaErrors] = useState(null);
  const [errorPostMessage, setErrorPostMessage] = useState("");
  const [timeSentAsc, setTimeSentAsc] = useState(true);
  const getTASKDATA = useSelector((state) => state.taskReducer.taskData);
  const [showBack, setShowBack] = useState(false);

  const [zipUploadingProgress, setZipUploadingProgress] = useState(0);

  const [, setGlobalTimer] = useLocalStorage("start-time", 0);

  useEffect(() => {
    if (getTASKDATA?.numTasks % 10 === 0) {
      setLastIndex(Math.floor(Number(getTASKDATA?.numTasks / 10)));
    } else {
      setLastIndex(Math.floor(Number(getTASKDATA?.numTasks / 10)) + 1);
    }
  }, [getTASKDATA?.numTasks]);

  const togglePopup = (data) => {
    setErrordata(data);
    setFounderror(!founderror);
  };
  const ErrorPopup = (data, type) => {
    if (type === "Modeling") {
      setErroname("Modeling");
    } else {
      setErroname("Qa");
    }

    setIconpopup(true);
    if (data) {
      setIconId(data);
    }
  };
  const togglePopup2 = (data) => {
    setFounderror2(data);
  };
  const togglePopup3 = (data) => {
    setFounderror3(data);
  };
  const postErrorSuccess = (data) => {
    setafterPostErrorSuccess(true);
  };
  const postErrorError = (data) => {
    setafterPostErrorFail(true);
  };
  const togglePopupforapprove = async (data1) => {
    setapproved(data1);
    let a;
    for (let i = 0; i < data1?.reporterrors?.length; i++) {
      if (data1.reporterrors[i].icon === false) {
        setIfapprove(false);
        a = false;
        break;
      } else {
        setIfapprove(true);
        a = true;
      }
    }
    if (a === true) {
      let index = Data?.indexOf(data1);
      Data?.splice(index, 1);
      setData(Data);
    }
  };
  const copy = (data1) => {
    navigator.clipboard.writeText(data1);
  };
  const handleSort = async (type) => {
    let newData = [...DATA];
    if (type === "type") {
      newData = newData.sort((a, b) => (b.type <= a.type ? 1 : -1));
      setDATA(newData);
    }
    if (type === "timeSent") {
      if (timeSentAsc === true) {
        newData = newData.sort((a, b) => (b.timeSent <= a.timeSent ? 1 : -1));
        setDATA(newData);
        setTimeSentAsc(false);
      } else {
        newData = newData.sort((a, b) => (b.timeSent >= a.timeSent ? 1 : -1));
        setDATA(newData);
        setTimeSentAsc(true);
      }
    }
    if (type === "timeLeft") {
      newData = newData.sort((a, b) => (b.timeLeft <= a.timeLeft ? 1 : -1));
      setDATA(newData);
    }
    if (type === "reportedErrors") {
      newData = newData.sort((a, b) =>
        b.reportedErrors <= a.reportedErrors ? 1 : -1
      );
      setDATA(newData);
    }
    if (type === "foundErrors") {
      newData = newData.sort((a, b) =>
        b.foundErrors <= a.foundErrors ? 1 : -1
      );
      setDATA(newData);
    }
    if (type === "claimedby") {
      newData = newData.sort((a, b) => (b.status <= a.status ? 1 : -1));
      setDATA(newData);
    }
  };

  const handlePagination = async (page) => {
    if (activePagination !== page) {
      const into10 = (page - 1) * 10;
      setStartd(into10);
      setEndd(into10 + 10);
      dispatch(setActiveClaimPage(page));
    }
  };

  const handleShowEstimates = (roomId) => {
    history.push(`/app/lineitems/${roomId}`);
  };

  useEffect(() => {
    getData(startd, endd);
    // eslint-disable-next-line
  }, [startd, endd]);

  useEffect(() => {
    if (getTASKDATA) {
      setDATA(getTASKDATA.tasks);
    }
  }, [getTASKDATA]);

  const getData = async (start, end, roomId = null) => {
    setShowBack(Boolean(roomId));
    setLoadingScreen(true);
    let myHeaders = new Headers();
    myHeaders.append("x-api-key", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
    myHeaders.append("Authorization", user.token);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const url = roomId
      ? `${urls.ApiUrl}?roomId=${roomId}&start=${start}&end=${end}`
      : `${urls.ApiUrl}?start=${start}&end=${end}`;
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.type === "QA") {
          result.tasks = result.tasks.map((e) => {
            return {
              ...e,
              status: e.status === "unclaimed" ? "claimable" : e.status,
            };
          });
          dispatch(getTask(result));
          setLoadingScreen(false);
        } else {
          history.replace("/app/modelingDashboard");
          setLoadingScreen(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoadingScreen(false);
      });
  };

  useEffect(() => {
    let interval = setInterval(() => {
      let myHeaders = new Headers();
      myHeaders.append("x-api-key", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
      myHeaders.append("Authorization", user.token);
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(`${urls.ApiUrl}?start=${0}&end=${10}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(getTASKDATA?.numTasks, result.numTasks, result);
          if (result.type === "QA") {
            if (getTASKDATA?.numTasks !== result.numTasks) setRefresh(true);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }, 15000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [DATA]);

  const claimeUser = async (id, name, data) => {
    let temp = {
      tasks: [data],
    };
    dispatch(selectedData(temp, history, false));
    const myHeaders = new Headers();
    myHeaders.append("x-api-key", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", user.token);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const resp = await fetch(
        `${urls.ApiUrl}/${id}?status=${name}&userId=${user.token}`,
        requestOptions
      );

      if (resp.status === 400) {
        setClaimedErrorText("Someone else already claimed that task");
        setClaimpopupFailed(!claimpopupFailed);
        return;
      }

      const respData = await resp.json();
      if (respData.status) {
        await getData(startd, endd);
        setClaimpopup(!claimpopup);
      } else {
        setClaimedErrorText(respData.message);
        setClaimpopupFailed(!claimpopupFailed);
      }
    } catch (e) {
      setClaimedErrorText("Failed to claim the task");
      setClaimpopupFailed(!claimpopupFailed);
    }
  };

  const onDropAccepted = useCallback(
    async (acceptedFiles) => {
      // Do something with the files
      let temp = showinputdata;
      await formdata.append("key", temp["key"]);
      await formdata.append("x-amz-algorithm", temp["x-amz-algorithm"]);
      await formdata.append("x-amz-credential", temp["x-amz-credential"]);
      await formdata.append("x-amz-date", temp["x-amz-date"]);
      await formdata.append(
        "x-amz-security-token",
        temp["x-amz-security-token"]
      );
      await formdata.append("policy", temp["policy"]);
      await formdata.append("x-amz-signature", temp["x-amz-signature"]);
      await formdata.append("file", acceptedFiles[0]);
      await uploadfileser(formdata);
    },
    // eslint-disable-next-line
    [showinputdata]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
    acceptedFiles,
  } = useDropzone({
    onDropAccepted,
    accept: {
      "application/zip": [".zip"],
    },
    validator: (file) => {
      if (!file.name.includes(DATA?.[0]?.roomId)) {
        return {
          code: "name-not-match",
          message: "File name should match with the room id",
        };
      }

      return null;
    },
  });

  const uploadser = (roomid, id, linktype) => {
    setLoadingUpdate(true);
    let myHeaders = new Headers();
    myHeaders.append("x-api-key", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
    myHeaders.append("Authorization", user.token);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${urls.ApiUrl}/${id}?roomId=${roomid}&linkType=${linktype}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((blob) => {
        console.log(blob.response_url);
        setResponseurldata(blob.response_url);
        setShowinputdata(blob.fields);
        setShowinputfile(true);
        setLoadingUpdate(false);
      })
      .catch((error) => {
        setLoadingUpdate(false);
        throw error;
      });
  };
  const uploadfileser = (data) => {
    axios
      .post(`${responseurldata}`, data, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setZipUploadingProgress(percentCompleted);
        },
      })
      .then((response) => response.json())
      .then((blob) => {
        console.log(blob);
        claimeUser(selectedItemId, "remodel", selectedItemData);
        setShowinputfile(false);
        setZipUploadingProgress(0);
      })
      .catch((error) => {
        console.log("error", error);
        claimeUser(selectedItemId, "remodel", selectedItemData);
        setShowinputfile(false);
        setZipUploadingProgress(0);
      });
  };

  const showMyData = () => {
    setClaimpopupFailed(!claimpopupFailed);
  };

  const download = async (roomid, id, claimId, linktype) => {
    try {
      const downloadType = linktype.slice(-3);
      const myHeaders = new Headers();
      myHeaders.append("x-api-key", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
      myHeaders.append("Authorization", user.token);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const res = await fetch(
        `${urls.ApiUrl}/${id}?claimId=${claimId}&roomId=${roomid}&linkType=${linktype}`,
        requestOptions
      );

      const { status } = res;
      const data = await res.json();

      if (status === 404) {
        toast.error(`${downloadType} link is not available!`);
        return;
      }
      if (data?.link) {
        const link = document.createElement("a");
        link.href = data.link;
        link.download = `${downloadType}-${id}`;
        link.click();
      }
    } catch (e) {
      toast.error(e.message);
    }
  };
  const downloadLink = (roomid, id, claimId, linktype) => {
    let myHeaders = new Headers();
    myHeaders.append("x-api-key", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
    myHeaders.append("Authorization", user.token);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${urls.ApiUrl}/${id}?claimId=${claimId}&roomId=${roomid}&linkType=${linktype}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((blob) => {
        if (
          blob["linkToPortal:"] !== undefined &&
          blob["linkToPortal:"] !== ""
        ) {
          window.open(blob["linkToPortal:"]);
        } else {
          window.open(blob.link);
        }
      })
      .catch((e) => toast.error(e.message));
  };
  const postError = (data) => {
    const ERRORARRAY = [];
    allerrorDisplay?.forEach((data) => {
      if (data.value === true) {
        ERRORARRAY.push(data.name);
      }
    });
    const myHeaders = new Headers();
    myHeaders.append("x-api-key", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", user.token);

    let errors = [];
    activeQaErrors?.forEach((cur) => {
      errors.push(cur);
    });
    const raw = JSON.stringify({
      errors: errors,
      comments: comment ? comment : "",
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${urls.ApiUrl}/${data}/errors`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (!result.id) {
          setActiveQaErrors(null);
          postErrorError();
          setFounderror(false);
          setErrorPostMessage(result.message);
        } else {
          setErrorPostMessage(result["message:"]);
          getData(startd, endd);
          setComment(null);
          setFounderror(false);
          postErrorSuccess();
          setActiveQaErrors(null);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setActiveQaErrors(null);
      });
  };

  const textareaInput = (event) => {
    const value = event.target.value;
    setComment(value);
  };

  const selectErrors = (data, comment = "", status) => {
    allQAErrors?.forEach((error) => {
      if (data === error) {
        if (status === true) {
          let Errors = [];
          activeQaErrors
            ?.filter((cur) => Object.keys(cur)[0] !== data)
            .forEach((current) => {
              Errors.push(current);
            });
          setActiveQaErrors(Errors);
          console.log(Errors);
        } else {
          if (
            activeQaErrors?.filter((cur) => Object.keys(cur)[0] === data)
              .length === 0
          ) {
            setActiveQaErrors([...activeQaErrors, { [data]: comment }]);
          } else {
            let dta = [];
            activeQaErrors
              ?.filter((cur) => Object.keys(cur)[0] !== data)
              .forEach((current) => {
                dta.push(current);
              });
            setActiveQaErrors([...dta, { [data]: comment }]);
            console.log(dta);
          }
        }
      }
    });
  };

  const claimedHeaders = [
    { key: "roomId", label: "Room ID" },
    { key: "taskType", label: "Task Type", sortable: true },
    { key: "timeSent", label: "Room Created", sortable: true },
    { key: "claimedTime", label: "Claimed Time", sortable: true },
  ];

  const unclaimedHeaders = [
    { key: "roomId", label: "Room ID" },
    { key: "taskType", label: "Task Type", sortable: true },
    { key: "timeSent", label: "Room Created", sortable: true },
  ];

  const additionalHeaders = (data) => {
    const firstItem = data[0];
    if (
      firstItem?.status !== "claimable" &&
      firstItem?.tasktype === "imageQA"
    ) {
      return [
        { key: "claimedTime", label: "Claimed Time", sortable: true },
        { key: "proceed", label: "Proceed", className: "npspacforheader" },
      ];
    }
    return [];
  };

  const getHeaders = (isClaimed, data) => {
    if (isClaimed) {
      return claimedHeaders;
    }
    return unclaimedHeaders.concat(additionalHeaders(data));
  };

  const TableHeader = ({ handleSort, data, isClaimed }) => {
    const headers = getHeaders(isClaimed, data);
    return (
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header.key} className={header.className}>
              {header.sortable ? (
                <SortableHeader
                  label={header.label}
                  onClick={() => handleSort(header.key)}
                />
              ) : (
                header.label
              )}
            </th>
          ))}
          {additionalHeaders(data).map((header) => (
            <th key={header.key} className={header.className}>
              {header.sortable ? (
                <SortableHeader
                  label={header.label}
                  onClick={() => handleSort(header.key)}
                />
              ) : (
                header.label
              )}
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  const SortableHeader = ({ label, onClick }) => (
    <div className="thcontent">
      <div className="thcontent1">
        <HiOutlineSortDescending className="sorticon" onClick={onClick} />
      </div>
      <div className="thcontent2">
        <p>{label}</p>
      </div>
    </div>
  );

  const TableRow = ({ data, index }) => {
    // Destructure data for easier access
    const {
      roomId,
      tasktype,
      timeSent,
      timeLeft,
      status,
      claimedBy,
      claimTime,
      pipelineFlow,
      fml,
      esx,
    } = data;

    const isClaimable = status === "claimable" || status === "available";
    const isImageQA = tasktype === "imageQA";

    return (
      <tr key={index} className="bordertr">
        {/* Room ID Cell */}
        <td style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <span>{roomId}</span>
          <img
            alt="copy room id icon"
            onClick={() => copy(roomId)}
            src={type || ""}
            style={{ cursor: "pointer" }}
          />
        </td>

        {/* Task Type Cell */}
        <td>{tasktype}</td>

        {/* Room Created Time Cell */}
        <td>{new Date(Number(timeSent * 1000)).toLocaleString()}</td>

        {/* Claimed Time Cell */}
        {!isClaimable && claimTime && (
          <td>{new Date(Number(claimTime * 1000)).toLocaleString()}</td>
        )}

        {/* Claim Button */}
        <td>
          {isClaimable ? (
            <button
              className="btn btn-primary"
              onClick={async () => {
                dispatch(
                  selectedData(
                    {
                      tasks: [data],
                    },
                    history,
                    false
                  )
                );
                mixpanel.qa.track(events.CLAIMED, data);
                await claimeUser(data.id, "claimed", data);
              }}
              style={{
                width: "max-content",
                padding: "0px 5px",
              }}
            >
              Claimable
            </button>
          ) : (
            <button
              className="btn btn-primary"
              onClick={() => {
                mixpanel.qa.track(events.UNCLAIMED, data);
                setGlobalTimer(0);
                claimeUser(data.id, "unclaimed", data);
              }}
              style={{
                width: "max-content",
                padding: "0px 5px",
                backgroundColor: "#e36a62", // red color
              }}
            >
              Unclaim
            </button>
          )}
        </td>
        {/* Claimed By / Actions */}
        {!isClaimable && (
          <>
            <td>
              {isImageQA ? null : (
                <div className="accesslink">
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      downloadLink(
                        roomId,
                        data.id,
                        data.claimId,
                        data.tasktype === "modelling"
                          ? "simplifiedPortal"
                          : "portal"
                      )
                    }
                    style={{
                      width: "max-content",
                      padding: "0px 5px",
                    }}
                  >
                    View Room
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      downloadLink(
                        roomId,
                        data.id,
                        data.claimId,
                        "downloadInstruction"
                      )
                    }
                    style={{
                      width: "max-content",
                      padding: "0px 5px",
                      backgroundColor: "#393e63", // dark blue color
                    }}
                  >
                    Instructions Zip
                  </button>
                  {(tasktype === "modeling" || tasktype === "QA") && (
                    <>
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          downloadLink(
                            roomId,
                            data.id,
                            data.claimId,
                            "downloadModeling"
                          )
                        }
                        style={{
                          width: "max-content",
                          padding: "0px 5px",
                          backgroundColor: "#393e63", // dark blue color
                        }}
                      >
                        Modeling Zip
                      </button>
                    </>
                  )}
                </div>
              )}
            </td>
          </>
        )}

        {/* Additional Actions */}
        {!isClaimable && !isImageQA && (
          <>
            <td className="npspac">
              <button
                className="btn btn-primary"
                onClick={() => {
                  try {
                    uploadser(data.roomId, data.id, "upload");
                    setselectedItemId(data.id);
                    setselectedItemData(data);
                  } catch (error) {
                    toast.error("Error uploading modeling");
                  }
                }}
                style={{
                  opacity: status !== "claimable" ? 1 : 0.5,
                  cursor: "pointer",
                  width: "max-content",
                  padding: "0px 5px",
                  backgroundColor: "#008080", // light blue color
                }}
              >
                Upload Modeling
              </button>
            </td>

            {/* Estimates Button */}
            {status === "claimed" && tasktype === "QA" && (
              <>
                <td>
                  {fml && (
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        download(roomId, data.id, data.claimId, "downloadFML")
                      }
                      style={{
                        width: "max-content",
                        padding: "0px 5px",
                        backgroundColor: "#393e63", // dark blue color
                      }}
                    >
                      FML
                    </button>
                  )}
                  {esx && (
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        download(roomId, data.id, data.claimId, "downloadESX")
                      }
                      style={{
                        width: "max-content",
                        padding: "0px 5px",
                        backgroundColor: "#393e63", // dark blue color
                      }}
                    >
                      ESX
                    </button>
                  )}
                  <button
                    className="btn btn-primary"
                    onClick={() => handleShowEstimates(data.roomId)}
                    style={{
                      width: "max-content",
                      padding: "0px 5px",
                    }}
                  >
                    Review Line Items
                  </button>
                </td>
                <td className="npspac">
                  <button
                    className="btn btn-success"
                    onClick={() =>
                      data.status !== "claimable"
                        ? data.status === "approved"
                          ? null
                          : claimeUser(data.id, "approved")
                        : null
                    }
                    style={{
                      opacity: status !== "claimable" ? 1 : 0.5,
                      cursor: status !== "claimable" ? "pointer" : "default",
                      width: "max-content",
                      padding: "0px 5px",
                      backgroundColor: "#387d4f", // green color
                    }}
                  >
                    Approve
                  </button>
                </td>
              </>
            )}
          </>
        )}

        {/* Proceed Button */}
        {!isClaimable && isImageQA && (
          <td>
            <button
              className="btn btn-primary"
              onClick={() => {
                let temp = {
                  tasks: [data],
                };
                dispatch(selectedData(temp, history, true));
                history.push({
                  pathname: "/app/maincomponent/roomsPreview",
                });
                setGlobalTimer(Date.now());
                mixpanel.qa.track(events.PROCEED_TO_IMAGE_QA, data);
              }}
              style={{
                width: "max-content",
                padding: "0px 5px",
              }}
            >
              Proceed to Image QA
            </button>
          </td>
        )}
      </tr>
    );
  };

  const DataTable = ({ DATA, handleSort }) => {
    if (!Array.isArray(DATA) || DATA.length === 0) {
      return (
        <div className="empty-table">
          <p>No data available to display.</p>
        </div>
      );
    }

    const validData = DATA.filter(
      (data) => data && typeof data === "object" && data.roomId && data.status
    );

    if (validData.length === 0) {
      return (
        <div className="empty-table">
          <p>Data contains no valid entries to display.</p>
        </div>
      );
    }
    console.log(validData);
    const claimable =
      validData[0].status === "claimable" ||
      validData[0].status === "available";

    return (
      <table>
        <TableHeader
          handleSort={handleSort}
          data={validData[0]}
          isClaimed={!claimable}
        />
        <tbody>
          {validData.map((data, index) => (
            <TableRow key={index} data={data} index={index} />
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="main">
      <Toaster position="top-right" />
      {loadingScreen ? (
        <p></p>
      ) : DATA?.length !== 0 ? (
        <div className="contain">
          <div className="searchbox">
            <div className="search-text-main">
              {showBack && (
                <Button
                  onClick={() => getData(startd, endd)}
                  sx={{ backgroundColor: "#537bb8" }}
                  variant="contained"
                  startIcon={<ArrowBack />}
                >
                  Back
                </Button>
              )}
            </div>
          </div>
          {refresh && (
            <h6 className="text-center">
              New Claims Available.{" "}
              <button
                onClick={() => {
                  setRefresh(false);
                  window.location.reload();
                }}
                className="btn btn-success btn-sm"
              >
                Refresh Now
              </button>
            </h6>
          )}
          <div style={{ padding: "10px 0px" }}>
            {!showBack && (
              <SearchComponent onSubmit={(roomId) => getData(0, 10, roomId)} />
            )}
          </div>
          <div className="table">
            <DataTable DATA={DATA} handleSort={handleSort} />
            {claimpopupFailed !== false && (
              <Popup2
                content={
                  <div className="resloved lessWidthPopup">
                    <>
                      <div className="closeresolved">
                        <AiOutlineClose
                          onClick={showMyData}
                          className="closeicon"
                        />
                      </div>
                      <div className="contentresolved">
                        <h5>{claimedErrorText}</h5>
                      </div>
                    </>
                  </div>
                }
                handleClose={togglePopupforapprove}
              />
            )}
            {Iconpopup !== false && (
              <Popup
                classerror={Erroname}
                content={
                  <div className="resloved ShowErrorPopup">
                    {Erroname === "Qa" ? (
                      IconId ? (
                        <div style={{ textAlign: "left", width: "70%" }}>
                          <h2>{Erroname} Errors</h2>
                          <table>
                            <tr>
                              <th>Error Type</th>
                              <th>Description</th>
                            </tr>
                            {allQAErrors?.map((cur) => {
                              if (
                                IconId?.errors?.filter(
                                  (fil) => Object.keys(fil)[0] === cur
                                ).length > 0
                              ) {
                                return (
                                  <tr>
                                    <td>
                                      <img
                                        alt="add item icons"
                                        style={{ width: "20px" }}
                                        className="additemicons"
                                        src={QualityCheck || ""}
                                      />{" "}
                                      <span>{cur}</span>
                                    </td>
                                    <td>
                                      {IconId?.errors
                                        ?.filter(
                                          (fil) => Object.keys(fil)[0] === cur
                                        )
                                        ?.map((cur1) => {
                                          return <span>{cur1[`${cur}`]}</span>;
                                        })}
                                    </td>
                                  </tr>
                                );
                              } else {
                                return (
                                  <tr>
                                    <td>
                                      <img
                                        alt="add item icon"
                                        style={{ width: "20px" }}
                                        className="additemicons"
                                        src={QualityCheck2 || ""}
                                      />{" "}
                                      <span>{cur}</span>
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </table>
                        </div>
                      ) : (
                        <div style={{ textAlign: "left" }}>
                          <h2>Errors</h2>
                          {allQAErrors.map((cur) => {
                            return (
                              <div style={{ margin: "7px", textAlign: "left" }}>
                                <img
                                  alt="add item icon"
                                  style={{ width: "20px" }}
                                  className="additemicons"
                                  src={QualityCheck2 || ""}
                                />{" "}
                                <span>{cur}</span>
                                <p>{cur}</p>
                              </div>
                            );
                          })}
                        </div>
                      )
                    ) : IconId ? (
                      <div style={{ textAlign: "left", width: "70%" }}>
                        <h2>{Erroname} Errors</h2>
                        <table>
                          <tr>
                            <th>Error Type</th>
                            <th>Description</th>
                          </tr>
                          {IconId?.errors?.map((cur) => {
                            return (
                              <tr>
                                <td>
                                  <img
                                    alt="add item icon"
                                    style={{ width: "20px" }}
                                    className="additemicons"
                                    src={QualityCheck || ""}
                                  />
                                  <span> {cur.type}</span>
                                </td>
                                <td>
                                  <span>{cur.comments}</span>
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                    ) : (
                      <div style={{ textAlign: "left" }}>
                        <h2>Errors</h2>
                        {IconId?.errors?.map((cur) => {
                          return (
                            <div style={{ margin: "7px", textAlign: "left" }}>
                              <img
                                alt="add item icon"
                                style={{ width: "20px" }}
                                className="additemicons"
                                src={QualityCheck2 || ""}
                              />{" "}
                              <span>{cur.type}</span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    <div className="addcomments">
                      <div>
                        <AiOutlineClose
                          onClick={() => {
                            setIconpopup(!Iconpopup);
                          }}
                          className="closeicon"
                        />
                      </div>
                      <div>
                        <textarea
                          value={
                            IconId?.comments !== ""
                              ? IconId?.comments
                              : "no comments posted"
                          }
                          placeholder="Add comments"
                          disabled
                          rows="12"
                          cols="49 "
                        ></textarea>
                      </div>
                    </div>
                  </div>
                }
                handleClose={togglePopupforapprove}
              />
            )}
            {afterPostErrorSuccess !== false && (
              <Popup2
                content={
                  <div className="resloved">
                    <>
                      <div className="closeresolved">
                        <AiOutlineClose
                          onClick={() => {
                            setafterPostErrorSuccess(!afterPostErrorSuccess);
                            setFounderror(false);
                          }}
                          className="closeicon"
                        />
                      </div>
                      <div className="contentresolved">
                        <h5>{errorPostMessage}</h5>
                      </div>
                    </>
                  </div>
                }
                handleClose={togglePopupforapprove}
              />
            )}
            {afterPostErrorFail !== false && (
              <Popup2
                content={
                  <div className="resloved">
                    <>
                      <div className="closeresolved">
                        <AiOutlineClose
                          onClick={() => {
                            setafterPostErrorFail(!afterPostErrorFail);
                            setFounderror(false);
                          }}
                          className="closeicon"
                        />
                      </div>
                      <div className="contentresolved">
                        <h5>{errorPostMessage}</h5>
                      </div>
                    </>
                  </div>
                }
                handleClose={togglePopupforapprove}
              />
            )}
            {founderror2 !== null && (
              <Popup
                content={
                  <div className="founderror2">
                    <div>
                      <AiOutlineClose
                        onClick={() => togglePopup2(null)}
                        className="closeicon2"
                      />
                    </div>
                    <div className="missingtitle">
                      <div style={{ width: "50%" }}>
                        <h5>Missing Item</h5>
                      </div>
                    </div>
                    <div className="missingcontent">
                      <div className="missingcontentrow1">
                        <h4>Comment by 3D vender</h4>
                        <hr />
                        <p>Label is missing the refrigerator</p>
                      </div>
                      <div className="missingcontentrow2">
                        <h4>Supporting images</h4>
                        <hr />
                        <div className="supportingimages">
                          <img alt="supportingimg" src={supportingimg || ""} />
                          <img alt="supportingimg" src={supportingimg || ""} />
                          <img alt="supportingimg" src={supportingimg || ""} />
                          <img alt="supportingimg" src={supportingimg || ""} />
                          <img alt="supportingimg" src={supportingimg || ""} />
                          <img alt="supportingimg" src={supportingimg || ""} />
                        </div>
                      </div>
                    </div>
                  </div>
                }
              />
            )}
            {founderror3 !== null && (
              <Popup
                content={
                  <div className="popupcontent">
                    <div className="selecterrors">
                      <p>
                        Which of the folloing issues have you resolved by
                        uploading{" "}
                      </p>
                      <p style={{ fontSize: "15px" }}>
                        Please check one Or multiple that applies
                      </p>
                      <ul>
                        <li>
                          <img
                            alt="add item icon"
                            className="additemicons"
                            src={QualityCheck || ""}
                          />
                          Inconsistent Number
                        </li>
                      </ul>
                    </div>
                    <div className="addcomments">
                      <div>
                        <AiOutlineClose
                          onClick={() => togglePopup3(null)}
                          className="closeicon"
                        />
                      </div>
                      <div>
                        <p>Please check or comment what you fixed</p>
                      </div>
                      <div className="checkicons">
                        <div className="checkiconcontent">
                          <TiTick className="checkicon" />
                          &nbsp; Corrected CSV
                        </div>
                        <div className="checkiconcontent">
                          <TiTick />
                          &nbsp; Change Materials
                        </div>
                        <div className="checkiconcontent">
                          <TiTick />
                          &nbsp; Remodel items
                        </div>

                        <div className="checkiconcontent">
                          <TiTick />
                          &nbsp; Resend to labeling
                        </div>
                        <div className="checkiconcontent">
                          <TiTick />
                          &nbsp; Renumber items
                        </div>
                        <div className="checkiconcontent">
                          <TiTick />
                          &nbsp; Adjust measurement
                        </div>
                      </div>
                      <div>
                        <textarea
                          placeholder="Add comments"
                          rows="08"
                          cols="49 "
                        ></textarea>
                      </div>
                      <div className="resolvebutton">
                        <button className="savebutton">RESOLVE ISSUE</button>
                      </div>
                    </div>
                  </div>
                }
              />
            )}
            {errorpopup !== false && (
              <Popup
                content={DATA.map((data) => {
                  return data.reportedErrors
                    .filter((data) => data === null)
                    .map((data) => {
                      return (
                        <div className="popupcontent">
                          <div className="selecterrors">
                            <h5>Please Indicate Errors You Found </h5>
                            <p>Please check one Or multiple that applies</p>
                            <ul>
                              <li>
                                <img
                                  alt="add item icons"
                                  className="additemicons"
                                  src={QualityCheck || ""}
                                />
                                Inconsistent Number
                              </li>
                              <li>{data.type1}</li>
                            </ul>
                          </div>
                          <div className="addcomments">
                            <div>
                              <AiOutlineClose
                                onClick={() => setErrorpopup(!errorpopup)}
                                className="closeicon"
                              />
                            </div>
                            <div>
                              <textarea
                                onChange={textareaInput}
                                placeholder="Add comments"
                                rows="12"
                                cols="49 "
                              ></textarea>
                            </div>
                            <div>
                              <button
                                onClick={() => {
                                  // TODO: this whole piece of code is probably not in use.
                                  // postError(allerr)
                                }}
                                className="savebutton"
                              >
                                SAVE
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    });
                })}
              />
            )}
            {approved !== null && (
              <Popup
                content={
                  <div className="resloved">
                    {ifapprove === false ? (
                      <>
                        <div className="closeresolved">
                          <AiOutlineClose
                            onClick={() => {
                              setapproved(null);
                            }}
                            className="closeicon"
                          />
                        </div>
                        <div className="contentresolved">
                          <h5>
                            You Can't approve unless all issues has been
                            resolved
                          </h5>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="closeresolved">
                          <AiOutlineClose
                            onClick={() => {
                              setapproved(null);
                            }}
                            className="closeicon"
                          />
                        </div>
                        <div className="contentresolved">
                          <h5>Your issues has been approved</h5>
                        </div>
                      </>
                    )}
                  </div>
                }
                handleClose={togglePopupforapprove}
              />
            )}
          </div>
          {lastIndex > 1 ? (
            <Pagination
              page={activePagination}
              size="large"
              onChange={(_, page) => handlePagination(page)}
              count={lastIndex}
              color="primary"
            />
          ) : null}
          {showinputfile === true && (
            <Popup
              content={
                <div className="resloved">
                  <>
                    <div className="closeresolved">
                      <AiOutlineClose
                        onClick={() => {
                          setShowinputfile(false);
                          setZipUploadingProgress(0);
                        }}
                        className="closeicon"
                      />
                    </div>
                    <div className="contentresolved">
                      {!zipUploadingProgress ? (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <IoFileTrayStackedSharp className="dropzoneIcon" />
                          {isDragActive ? (
                            <p>Drop the files here ...</p>
                          ) : (
                            <p>
                              Drag 'n' drop some files here, or click to select
                              files
                            </p>
                          )}

                          {fileRejections.map(({ file, errors }) => (
                            <div key={file.path}>
                              {errors.map((e) => (
                                <p className="text-danger" key={e.code}>
                                  {e.message}
                                </p>
                              ))}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div>
                          <p>
                            Uploading{" "}
                            <span className="font-bold">
                              {acceptedFiles?.[0]?.name}
                            </span>
                          </p>
                          <LinearProgressWithLabel
                            value={zipUploadingProgress}
                          />
                        </div>
                      )}
                    </div>
                  </>
                </div>
              }
              handleClose={togglePopupforapprove}
            />
          )}
        </div>
      ) : (
        <div className="contain">
          <div className="searchbox">
            <div className="search-text-main"></div>
          </div>
          <div style={{ padding: "10px 0px" }}>
            {!showBack && (
              <SearchComponent onSubmit={(roomId) => getData(0, 10, roomId)} />
            )}
          </div>
          <div className="table">
            <table style={{ margin: 20 }}>
              <tr style={{ textAlign: "center" }}>
                <span>
                  There are no claimable tasks available. Please check later
                </span>
              </tr>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
export default QADashboard;
